import React from 'react';
import { Heading, Box } from 'rebass/styled-components';
import { Layout } from '../components/Layout';
import Section from '../components/Section';
import ContextProvider from '../components/ContextProvider';

// TODO: Complete 404 design and page
const NotFoundPage = ({ localeCode }: { localeCode?: string }) => (
  <ContextProvider localeCode={localeCode}>
    <Layout
      title="Page not found - CloudFit"
      description={`The page you're looking for could not be found`}
    >
      <Section.Container id="404" backgroundColor="light">
        <Box width={[320, 400, 600]} m="auto">
          <Heading
            color="primaryDark"
            fontSize={['9rem', '13rem', '16rem']}
            as="h1"
          >
            404
          </Heading>
          <Heading
            color="secondary"
            fontSize={['4rem', '5rem', '6rem']}
            as="h2"
          >
            There isn&apos;t anything here
          </Heading>
        </Box>
      </Section.Container>
    </Layout>
  </ContextProvider>
);

export default NotFoundPage;
